

.ReactModal__Body--open .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;

}

.red-link,
.red-link:hover,
.langueWrap a {
    color: #e00a00;
}